import React from "react"
import RacepageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Strade Bianche",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-04 17:19:31",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28362: {
        teamId: "28362",
        teamUciCode: "VAI",
        teamName: "Aromitalia Basso Bikes Vaiano",
        teamNationCode: "ITA",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28538: {
        teamId: "28538",
        teamUciCode: "TOP",
        teamName: "Top Girls Fassa Bortolo",
        teamNationCode: "ITA",
      },
      28562: {
        teamId: "28562",
        teamUciCode: "SBT",
        teamName: "Isolmant-Premac-Vittoria",
        teamNationCode: "ITA",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
      29009: {
        teamId: "29009",
        teamUciCode: "BPK",
        teamName: "BePink",
        teamNationCode: "ITA",
      },
      29297: {
        teamId: "29297",
        teamUciCode: "BTW",
        teamName: "Born To Win G20 Ambedo",
        teamNationCode: "ITA",
      },
      29302: {
        teamId: "29302",
        teamUciCode: "SER",
        teamName: "Servetto-Makhymo-Beltrami TSA",
        teamNationCode: "ITA",
      },
      29305: {
        teamId: "29305",
        teamUciCode: "MDS",
        teamName: "Team Mendelspeck",
        teamNationCode: "ITA",
      },
    },
    riders: {
      164720: {
        id: 164720,
        startno: 133,
        firstName: "Justine",
        lastName: "Ghekiere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-14",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 234,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      156228: {
        id: 156228,
        startno: 193,
        firstName: "Eva Maria",
        lastName: "Gatscher",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-04-05",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 121,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      93107: {
        id: 93107,
        startno: 151,
        firstName: "Marina",
        lastName: "Ivanuk",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1990-09-10",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 116,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89702: {
        id: 89702,
        startno: 23,
        firstName: "Nadia",
        lastName: "Quagliotto",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-03-22",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      157545: {
        id: 157545,
        startno: 204,
        firstName: "Vanessa",
        lastName: "Michieletto",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-05",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      89416: {
        id: 89416,
        startno: 181,
        firstName: "Marianne",
        lastName: "Vos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-05-13",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89711: {
        id: 89711,
        startno: 216,
        firstName: "Tayler",
        lastName: "Wiles",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-07-19",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 225,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      114520: {
        id: 114520,
        startno: 36,
        firstName: "Giorgia",
        lastName: "Simoni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-24",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89489: {
        id: 89489,
        startno: 226,
        firstName: "Erica",
        lastName: "Magnaldi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      93003: {
        id: 93003,
        startno: 236,
        firstName: "Mie Bj\u00c3\u00b8rndal",
        lastName: "Ottestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-07-17",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      96734: {
        id: 96734,
        startno: 76,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96878: {
        id: 96878,
        startno: 91,
        firstName: "Gaia",
        lastName: "Realini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-06-19",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      89471: {
        id: 89471,
        startno: 163,
        firstName: "Arianna",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-06",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 103,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89708: {
        id: 89708,
        startno: 213,
        firstName: "Lauretta",
        lastName: "Hanson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-10-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 175,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      156302: {
        id: 156302,
        startno: 154,
        firstName: "Silvia",
        lastName: "Bortolotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2003-05-27",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      93562: {
        id: 93562,
        startno: 6,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 43,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 171,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      97814: {
        id: 97814,
        startno: 13,
        firstName: "Francesca",
        lastName: "Baroni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-04",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      96868: {
        id: 96868,
        startno: 196,
        firstName: "Beatrice",
        lastName: "Pozzobon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-05-23",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      96773: {
        id: 96773,
        startno: 124,
        firstName: "Julia",
        lastName: "Van Bokhoven",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      178345: {
        id: 178345,
        startno: 85,
        firstName: "Kaia",
        lastName: "Schmid",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2003-01-07",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      91078: {
        id: 91078,
        startno: 115,
        firstName: "Sara",
        lastName: "Mart\u00c3\u00adn",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-03-22",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      156229: {
        id: 156229,
        startno: 32,
        firstName: "Anna",
        lastName: "Chili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-07-08",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 211,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114476: {
        id: 114476,
        startno: 215,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 174,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      96861: {
        id: 96861,
        startno: 52,
        firstName: "Camilla",
        lastName: "Alessio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-07-23",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      97831: {
        id: 97831,
        startno: 244,
        firstName: "Anastasia",
        lastName: "Carbonari",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89725: {
        id: 89725,
        startno: 22,
        firstName: "Michaela",
        lastName: "Drummond",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-04-05",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 161,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      114474: {
        id: 114474,
        startno: 66,
        firstName: "Abi",
        lastName: "Smith",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-04-01",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      156230: {
        id: 156230,
        startno: 95,
        firstName: "Asia",
        lastName: "Zontone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-11-04",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      99938: {
        id: 99938,
        startno: 14,
        firstName: "Inga",
        lastName: "Cesuliene",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1986-02-14",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      154244: {
        id: 154244,
        startno: 144,
        firstName: "Gulnaz",
        lastName: "Khatuntseva",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1994-04-21",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      101796: {
        id: 101796,
        startno: 131,
        firstName: "Sanne",
        lastName: "Cant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      150795: {
        id: 150795,
        startno: 186,
        firstName: "Linda",
        lastName: "Riedmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2003-03-23",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      105914: {
        id: 105914,
        startno: 192,
        firstName: "Caris",
        lastName: "Cosentino",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-18",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      89422: {
        id: 89422,
        startno: 104,
        firstName: "Jeanne",
        lastName: "Korevaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-09-24",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96702: {
        id: 96702,
        startno: 235,
        firstName: "Wilma",
        lastName: "Olausson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      106424: {
        id: 106424,
        startno: 61,
        firstName: "Magdeleine",
        lastName: "Vallieres Mill",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2001-08-10",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      96819: {
        id: 96819,
        startno: 21,
        firstName: "Silvia",
        lastName: "Zanardi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-03",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      91054: {
        id: 91054,
        startno: 203,
        firstName: "Greta",
        lastName: "Marturano",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-06-14",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      89423: {
        id: 89423,
        startno: 134,
        firstName: "Yara",
        lastName: "Kastelijn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-08-09",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 185,
        firstName: "Coryn",
        lastName: "Labecki",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      125411: {
        id: 125411,
        startno: 142,
        firstName: "Ines",
        lastName: "Cantera Carrasco",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-07-30",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96893: {
        id: 96893,
        startno: 35,
        firstName: "Giulia",
        lastName: "Luciani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-01-14",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 222,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 112,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89925: {
        id: 89925,
        startno: 75,
        firstName: "Victorie",
        lastName: "Guilman",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-03-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      103237: {
        id: 103237,
        startno: 83,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      96818: {
        id: 96818,
        startno: 73,
        firstName: "Vittoria",
        lastName: "Guazzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-12-26",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 54,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      91184: {
        id: 91184,
        startno: 72,
        firstName: "Grace",
        lastName: "Brown",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-07-07",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      110027: {
        id: 110027,
        startno: 173,
        firstName: "Franziska",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-07-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      97845: {
        id: 97845,
        startno: 153,
        firstName: "Sofia",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-06-21",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 1,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89533: {
        id: 89533,
        startno: 165,
        firstName: "Urska",
        lastName: "Zigart",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89534: {
        id: 89534,
        startno: 42,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-06-02",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90066: {
        id: 90066,
        startno: 12,
        firstName: "Francesca",
        lastName: "Balducci",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-05-01",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      89790: {
        id: 89790,
        startno: 195,
        firstName: "Francesca",
        lastName: "Pisciali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-19",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      110343: {
        id: 110343,
        startno: 123,
        firstName: "Quinty",
        lastName: "Schoens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-12",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      90125: {
        id: 90125,
        startno: 63,
        firstName: "Emily",
        lastName: "Newsom",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1983-09-04",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      155220: {
        id: 155220,
        startno: 232,
        firstName: "Rebecca",
        lastName: "Koerner",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-22",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      90853: {
        id: 90853,
        startno: 31,
        firstName: "Sara",
        lastName: "Casasola",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-29",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      96909: {
        id: 96909,
        startno: 206,
        firstName: "Giorgia",
        lastName: "Vettorello",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-07-06",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      89421: {
        id: 89421,
        startno: 184,
        firstName: "Anouska",
        lastName: "Koster",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-08-20",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      96716: {
        id: 96716,
        startno: 172,
        firstName: "L\u00c3\u00a9a",
        lastName: "Curinier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-04-21",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89984: {
        id: 89984,
        startno: 51,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89983: {
        id: 89983,
        startno: 242,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      92102: {
        id: 92102,
        startno: 2,
        firstName: "Niamh",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-08-12",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89538: {
        id: 89538,
        startno: 44,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      125733: {
        id: 125733,
        startno: 5,
        firstName: "Anna",
        lastName: "Shackley",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-17",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89781: {
        id: 89781,
        startno: 94,
        firstName: "Alice",
        lastName: "Gasparini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-12-14",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      89463: {
        id: 89463,
        startno: 11,
        firstName: "Rasa",
        lastName: "Leleivyte",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-07-22",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 143,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 102,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      93088: {
        id: 93088,
        startno: 135,
        firstName: "Laura",
        lastName: "S\u00c3\u00bc\u00c3\u009femilch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-02-23",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89932: {
        id: 89932,
        startno: 191,
        firstName: "Vania",
        lastName: "Canvelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-10-21",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      89831: {
        id: 89831,
        startno: 101,
        firstName: "Sabrina",
        lastName: "Stultiens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-08",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89527: {
        id: 89527,
        startno: 55,
        firstName: "Hanna",
        lastName: "Nilsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1992-02-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 243,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      96865: {
        id: 96865,
        startno: 16,
        firstName: "Gemma",
        lastName: "Sernissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-06",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      96879: {
        id: 96879,
        startno: 202,
        firstName: "Giorgia",
        lastName: "Bariani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-11-19",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      99107: {
        id: 99107,
        startno: 126,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      114918: {
        id: 114918,
        startno: 105,
        firstName: "Ayesha",
        lastName: "McGowan",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1987-04-02",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      154500: {
        id: 154500,
        startno: 92,
        firstName: "Ainara",
        lastName: "Albert Bosch",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2003-06-21",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      157566: {
        id: 157566,
        startno: 34,
        firstName: "Sofia",
        lastName: "Clerici",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-11-03",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 221,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      90019: {
        id: 90019,
        startno: 45,
        firstName: "Pauliena",
        lastName: "Rooijakkers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-05-12",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      92687: {
        id: 92687,
        startno: 53,
        firstName: "Laura",
        lastName: "Asencio",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-14",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89776: {
        id: 89776,
        startno: 62,
        firstName: "Letizia",
        lastName: "Borghesi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-16",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89497: {
        id: 89497,
        startno: 246,
        firstName: "Ilaria",
        lastName: "Sanguineti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-15",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 41,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      112278: {
        id: 112278,
        startno: 164,
        firstName: "Ruby",
        lastName: "Roseman-Gannon",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-11-08",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89535: {
        id: 89535,
        startno: 231,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      97844: {
        id: 97844,
        startno: 152,
        firstName: "Isotta\t",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-19",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 176,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 224,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 136,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89937: {
        id: 89937,
        startno: 65,
        firstName: "Omer",
        lastName: "Shapira",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-09-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      114527: {
        id: 114527,
        startno: 194,
        firstName: "Angela",
        lastName: "Oro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-05-27",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 122,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89588: {
        id: 89588,
        startno: 155,
        firstName: "Viktoriia",
        lastName: "Melnychuk",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-06-02",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89554: {
        id: 89554,
        startno: 71,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96875: {
        id: 96875,
        startno: 26,
        firstName: "Matilde",
        lastName: "Vitillo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-03-08",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      90993: {
        id: 90993,
        startno: 205,
        firstName: "Debora",
        lastName: "Silvestri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-08",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      97542: {
        id: 97542,
        startno: 182,
        firstName: "Teuntje",
        lastName: "Beekhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-18",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89503: {
        id: 89503,
        startno: 141,
        firstName: "Olga",
        lastName: "Zabelinskaya",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1980-05-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89917: {
        id: 89917,
        startno: 113,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 46,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89719: {
        id: 89719,
        startno: 241,
        firstName: "Silvia",
        lastName: "Persico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-07-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      96711: {
        id: 96711,
        startno: 233,
        firstName: "Amalie",
        lastName: "Lutro",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2000-03-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89736: {
        id: 89736,
        startno: 212,
        firstName: "Audrey",
        lastName: "Cordon-Ragot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-22",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114532: {
        id: 114532,
        startno: 93,
        firstName: "Alice",
        lastName: "Capasso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-12-23",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      114522: {
        id: 114522,
        startno: 24,
        firstName: "Prisca",
        lastName: "Savi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-21",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 4,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89883: {
        id: 89883,
        startno: 3,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      174041: {
        id: 174041,
        startno: 132,
        firstName: "Ronja",
        lastName: "Eibl",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-08-30",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      114517: {
        id: 114517,
        startno: 156,
        firstName: "Chia",
        lastName: "Pedrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-01-08",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      90014: {
        id: 90014,
        startno: 82,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      91167: {
        id: 91167,
        startno: 146,
        firstName: "Aline",
        lastName: "Seitz",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-02-17",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      90063: {
        id: 90063,
        startno: 64,
        firstName: "Sara",
        lastName: "Poidevin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1996-05-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      114511: {
        id: 114511,
        startno: 15,
        firstName: "Milena",
        lastName: "Del Sarto",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-12-03",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      89987: {
        id: 89987,
        startno: 201,
        firstName: "Tatiana",
        lastName: "Guderzo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-08-22",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      96745: {
        id: 96745,
        startno: 125,
        firstName: "Rosalie",
        lastName: "Van Der Wolf",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-03-11",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      90107: {
        id: 90107,
        startno: 86,
        firstName: "Lily",
        lastName: "Williams",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-06-24",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89866: {
        id: 89866,
        startno: 114,
        firstName: "Alicia",
        lastName: "Gonzalez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      159870: {
        id: 159870,
        startno: 33,
        firstName: "Tatiana",
        lastName: "Chili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-07-08",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 214,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89522: {
        id: 89522,
        startno: 223,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      108734: {
        id: 108734,
        startno: 25,
        firstName: "Jade",
        lastName: "T\u00c3\u00a9olis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2002-01-12",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      89484: {
        id: 89484,
        startno: 56,
        firstName: "Lara",
        lastName: "Vieceli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-07-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90865: {
        id: 90865,
        startno: 245,
        firstName: "Elena",
        lastName: "Pirrone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-02-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89435: {
        id: 89435,
        startno: 166,
        firstName: "Georgia",
        lastName: "Williams",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-08-25",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89425: {
        id: 89425,
        startno: 162,
        firstName: "Jessica",
        lastName: "Allen",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-04-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      93655: {
        id: 93655,
        startno: 183,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89496: {
        id: 89496,
        startno: 106,
        firstName: "Katia",
        lastName: "Ragusa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-19",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 111,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      97812: {
        id: 97812,
        startno: 145,
        firstName: "Diana",
        lastName: "Klimova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-10-08",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Strade Bianche"
  const raceID = 9044

  return (
    <RacepageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
